import Layout from '../components/layouts/en';
import React from 'react';
import Terms from '../components/Terms';

const IndexPage = ({ location, ...rest }) => (
  <Layout location={location}>
    <Terms />
  </Layout>
);

export default IndexPage;

import React from 'react';
import cx from 'classnames';

import Footer from '../Footer';
import Navbar from '../Navbar';

import styles from './index.module.sass';

export default class Terms extends React.Component {
  render() {
    return (
      <div className={styles.terms}>
        <div className="container">
          <Navbar path="terms" />
          <div className={cx('content', styles.content)}>
            <h2 className="has-text-centered has-text-weight-semibold">Terms of use</h2>

            <h3>TERMS AND CONDITIONS OF USE</h3>

            <p>
              THIS IS A SUBSCRIPTION SERVICE THAT AUTOMATICALLY RENEWS. PLEASE READ THESE TERMS AND
              CONDITIONS OF USE CAREFULLY (IN PARTICULAR, SECTION 6 &ldquo;SUBSCRIPTION FEES AND
              PAYMENT&rdquo;) BEFORE STARTING A FREE TRIAL OR COMPLETING A PURCHASE FOR HEROSNATCH:
              WEIGHT LOSS WORKOUTS AUTO-RENEWING SUBSCRIPTION SERVICE. YOU MUST AFFIRMATIVELY CANCEL
              A SUBSCRIPTION OR A FREE TRIAL TO AVOID BEING CHARGED IN YOUR APP STORE&rsquo;S
              ACCOUNT SETTINGS AT LEAST 24 HOURS BEFORE THE END OF THE FREE TRIAL OR THE CURRENT
              SUBSCRIPTION PERIOD. IF YOU ARE UNSURE HOW TO CANCEL A SUBSCRIPTION OR A FREE TRIAL,
              PLEASE VISIT THE APPLE SUPPORT WEBSITE, GOOGLE PLAY HELP (OR ANY OTHER APP STORES
              SUPPORT PAGES). DELETING THE APP DOES NOT CANCEL YOUR SUBSCRIPTIONS AND FREE TRIALS.
              YOU MAY WISH TO MAKE A PRINTSCREEN OF THIS INFORMATION FOR YOUR REFERENCE.
            </p>

            <h4>1. ACCEPTANCE OF TERMS</h4>

            <p />

            <p>
              1.1. HeroSnatch: Weight Loss Workouts (the &quot;App&quot;) and the content available
              via the App or our emails (the &quot;Content&quot;) are distributed by HeroSnatch LLC
              or other company as may be indicated in an app store (&quot;we&quot;, &quot;us&quot;,
              &quot;our&quot; or the &quot;Company&quot;). The App, together with the Content,
              tools, transactions and other services available by using the App, are collectively
              referred to as the &quot;Service&quot;.
            </p>

            <p />

            <p>
              1.2. Your access and use of the Service constitutes your agreement to be bound by
              these Terms and Conditions of Use (the &quot;Terms&quot;), which establishes a legally
              binding contractual relationship between you and the Company. For this reason, PLEASE
              READ THE TERMS CAREFULLY BEFORE USING THE SERVICE.
            </p>

            <p />

            <p>
              1.3. Please review also our Privacy Policy. The terms of the Privacy Policy and other
              supplemental terms, policies or documents that may be posted on the Service from time
              to time are hereby expressly incorporated herein by reference. We reserve the right,
              in our sole discretion, to make changes or modifications to these Terms at any time
              and for any reason.
            </p>

            <p />

            <p>
              1.4. Unless otherwise expressly provided herein, we will alert you about any changes
              by updating the &quot;Last updated&quot; date of these Terms and you waive any right
              to receive specific notice of each such change.
            </p>

            <p />

            <p>
              1.5. THESE TERMS CONTAIN IMPORTANT DISCLAIMERS (SECTION 2), DISCLAIMERS OF WARRANTIES
              (SECTION 8), LIMITATION OF LIABILITY (SECTION 9), AS WELL AS PROVISIONS THAT WAIVE
              YOUR RIGHT TO A JURY TRIAL, RIGHT TO A COURT HEARING AND RIGHT TO PARTICIPATE IN A
              CLASS ACTION (ARBITRATION AND CLASS ACTION WAIVER). UNLESS YOU OPT OUT WITHIN 30 DAYS
              OF FIRST USE OF OUR SERVICE AS PROVIDED FOR IN SECTION 12, ARBITRATION IS THE
              EXCLUSIVE REMEDY FOR ANY AND ALL DISPUTES AND IS MANDATORY EXCEPT AS SPECIFIED BELOW
              IN SECTION 12.
            </p>

            <p />

            <p>
              1.6. IF YOU DO NOT AGREE WITH ANY PART OF THESE TERMS, OR IF YOU ARE NOT ELIGIBLE OR
              AUTHORIZED TO BE BOUND BY THESE TERMS, THEN DO NOT DOWNLOAD THE APP OR OTHERWISE
              ACCESS OR USE THE SERVICE.
            </p>

            <p />

            <h4>2. IMPORTANT DISCLAIMERS</h4>

            <p />

            <p>
              2.1. THE COMPANY DOES NOT OFFER OR PROVIDE ANY MEDICAL ADVICE, HEALTH INSURANCE OR
              OTHER HEALTHCARE SERVICE, INCLUDING WITHOUT LIMITATION, ANY COUNSELING, TESTING,
              EVALUATION, PRESCRIPTION, PROCEDURE OR THERAPY RELATED TO EXERCISE, NUTRITION, WEIGHT
              LOSS OR WELLNESS OR RELATED TO THE AVOIDANCE, PREVENTION, DIAGNOSIS OR TREATMENT OF
              ANY INJURY, ILLNESS, DISEASE OR CONDITION (COLLECTIVELY, &quot;HEALTHCARE
              SERVICES&quot;).
            </p>

            <p />

            <p>
              2.2. THE SERVICE MAY NOT BE APPROPRIATE FOR ALL PERSONS AND IS NOT A SUBSTITUTE FOR
              PROFESSIONAL HEALTHCARE SERVICES. THE SERVICE IS INTENDED ONLY AS A TOOL, WHICH MAY BE
              USEFUL IN ACHIEVING YOUR OVERALL HEALTH AND FITNESS GOALS. YOU ACKNOWLEDGE THAT YOUR
              DIET AND EXERCISE ACTIVITIES INVOLVE RISKS WHICH MAY INVOLVE RISK OF BODILY INJURY OR
              DEATH, AND THAT YOU ASSUME THOSE RISKS. BEFORE ACCESSING OR USING THE SERVICE, AND
              AGREE TO RELEASE AND DISCHARGE THE COMPANY FROM ANY AND ALL ACTION, KNOWN OR UNKNOWN,
              ARISING OUT OF YOUR USE OF THE SERVICE.
            </p>

            <p />

            <p>
              2.3. YOU SHOULD CONSULT WITH YOUR PHYSICIAN OR OTHER QUALIFIED HEALTHCARE PROFESSIONAL
              TO DETERMINE WHETHER THE SERVICE WOULD BE SAFE AND EFFECTIVE FOR YOU. YOU ARE
              EXPRESSLY PROHIBITED FROM ACCESSING OR USING THE SERVICE AGAINST MEDICAL ADVICE OR IF
              DOING SO MIGHT POSE ANY HEALTH RISK. IN THIS CONTEXT, YOU ACKNOWLEDGE THAT YOU TAKE
              FULL RESPONSIBILITY FOR YOUR HEALTH, LIFE AND WELL-BEING, AS WELL AS THE HEALTH, LIVES
              AND WELL-BEING OF YOUR FAMILY AND CHILDREN (BORN AND UNBORN, AS APPLICABLE), AND ALL
              DECISIONS NOW OR IN THE FUTURE.
            </p>

            <p />

            <p>
              2.4. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY AGREE THAT WE
              ARE NOT PROVIDING MEDICAL ADVICE VIA THE SERVICE. ALL CONTENT PROVIDED THROUGH THE
              SERVICE, WHETHER PROVIDED BY US OR THIRD PARTIES (EVEN IF THEY ARE CLAIMING TO BE A
              DOCTOR) IS NOT INTENDED TO BE AND SHOULD NOT BE USED IN PLACE OF (I) THE ADVICE OF
              YOUR PHYSICIAN OR OTHER PROFESSIONALS, (II) A VISIT, CALL OR CONSULTATION WITH YOUR
              PHYSICIAN OR OTHER MEDICAL PROFESSIONALS, OR (III) INFORMATION CONTAINED ON OR IN ANY
              PRODUCT PACKAGING OR LABEL. WE ARE NOT RESPONSIBLE FOR ANY HEALTH PROBLEMS THAT MAY
              RESULT FROM TRAINING PROGRAMS, CONSULTATIONS, PRODUCTS, OR EVENTS YOU LEARN ABOUT
              THROUGH THE SERVICE. SHOULD YOU HAVE ANY HEALTH-RELATED QUESTIONS, PLEASE CALL OR SEE
              YOUR PHYSICIAN OR OTHER HEALTHCARE PROVIDER PROMPTLY. IF YOU HAVE AN EMERGENCY, CALL
              YOUR PHYSICIAN OR YOUR LOCAL EMERGENCY SERVICES IMMEDIATELY.
            </p>

            <p />

            <p>
              2.5. YOUR USE OF THE SERVICE DOES NOT CONSTITUTE OR CREATE A DOCTOR-PATIENT,
              THERAPIST-PATIENT OR OTHER HEALTHCARE PROFESSIONAL RELATIONSHIP BETWEEN YOU AND THE
              COMPANY.
            </p>

            <p />

            <p>
              2.6. THE COMPANY DOES NOT ASSUME ANY LIABILITY FOR INACCURACIES OR MISSTATEMENTS ABOUT
              FOOD RECIPES, EXERCICES OR OTHER CONTENT ON THE SERVICE. YOU SHOULD CAREFULLY READ ALL
              INFORMATION PROVIDED BY THE MANUFACTURERS OF THE FOOD PRODUCTS, WHETHER ONLINE OR ON
              THE ACTUAL PRODUCT PACKAGING AND LABELS, INCLUDING NUTRIENT CONTENT, INGREDIENTS, FOOD
              ALLERGEN AND CONTACT INFORMATION, AND HEALTH CLAIMS, BEFORE USING OR CONSUMING A
              PRODUCT. FOR ADDITIONAL INFORMATION ABOUT A FOOD PRODUCT, PLEASE CONTACT THE
              MANUFACTURER DIRECTLY.
            </p>

            <p />

            <p>
              2.7. WE MAKE NO GUARANTEES CONCERNING THE LEVEL OF SUCCESS YOU MAY EXPERIENCE, AND YOU
              ACCEPT THE RISK THAT RESULTS WILL DIFFER FOR EACH INDIVIDUAL. THE TESTIMONIALS AND
              EXAMPLES THAT MAY BE PROVIDED ON THE SERVICE ARE EXCEPTIONAL RESULTS, WHICH DO NOT
              APPLY TO AN AVERAGE PERSON, AND ARE NOT INTENDED TO REPRESENT OR GUARANTEE THAT ANYONE
              WILL ACHIEVE THE SAME OR SIMILAR RESULTS. THERE IS NO ASSURANCE THAT EXAMPLES OF PAST
              FITNESS RESULTS CAN BE DUPLICATED IN THE FUTURE. WE CANNOT GUARANTEE YOUR FUTURE
              RESULTS AND/OR SUCCESS. NOR CAN WE GUARANTEE THAT YOU MAINTAIN THE RESULTS YOU
              EXPERIENCE IF YOU DO NOT CONTINUE FOLLOWING OUR PROGRAMS.
            </p>

            <p />

            <p>
              2.8. EACH INDIVIDUAL'S HEALTH, FITNESS, AND NUTRITION SUCCESS DEPENDS ON HIS OR HER
              BACKGROUND, DEDICATION, DESIRE, AND MOTIVATION. AS WITH ANY HEALTH-RELATED PROGRAM OR
              SERVICE, YOUR RESULTS MAY VARY AND WILL BE BASED ON MANY VARIABLES, INCLUDING BUT NOT
              LIMITED TO, YOUR CAPACITY, LIFE EXPERIENCE, UNIQUE HEALTH AND GENETIC PROFILE,
              STARTING POINT, EXPERTISE, AND LEVEL OF COMMITMENT. THE USE OF THE SERVICE SHOULD BE
              BASED ON YOUR OWN DUE DILIGENCE, AND YOU AGREE THAT THE COMPANY IS NOT LIABLE FOR ANY
              SUCCESS OR FAILURE OF YOUR PHYSIQUE THAT IS DIRECTLY OR INDIRECTLY RELATED TO THE
              PURCHASE AND USE OF THE SERVICE.
            </p>

            <p />

            <p>
              2.9. IN ADDITION TO ALL OTHER LIMITATIONS AND DISCLAIMERS IN THESE TERMS, THE COMPANY
              DISCLAIMS ANY LIABILITY OR LOSS IN CONNECTION WITH THE CONTENT PROVIDED ON THE
              SERVICE. YOU ARE ENCOURAGED TO CONSULT WITH YOUR DOCTOR AND OTHER RELEVANT
              PROFESSIONALS ABOUT THE INFORMATION CONTAINED ON OR ACCESSED THROUGH THE SERVICE.
            </p>

            <p />

            <p />

            <h4>3. PROFILE REGISTRATION</h4>

            <p />

            <p>
              3.1. In order to use certain features of the Service, you must register your profile
              in the App (&quot;Profile&quot;) and provide certain information about yourself as
              prompted by the registration form.
            </p>

            <p />

            <p>
              3.2. If you register the Profile, you represent and warrant to the Company that: (i)
              all required registration information you submit is truthful and accurate; (ii) you
              will maintain the accuracy of such information; and (iii) your use of the Service does
              not violate any applicable law or regulation or these Terms. Otherwise, the Service
              may not operate correctly, and we may not be able to contact you with important
              notices.
            </p>

            <p />

            <p>
              3.3. The Service is not intended to be used by individuals under the age of 16. You
              hereby represent and warrant to the Company that you meet the foregoing qualification.
              All users who are minors in the jurisdiction in which they reside (generally under the
              age of 18) must have the permission of and be directly supervised by their parent or
              guardian to use the Service. If you are a minor, you must have your parent or guardian
              read and agree to these Terms before you use the Service.
            </p>

            <p />

            <p>
              3.4. The Company reserves the right to suspend or terminate your Profile, or your
              access to the Service, with or without notice to you, in the event that you breach
              these Terms.
            </p>

            <p />

            <p>
              3.5. You are responsible for maintaining the confidentiality of your Profile login
              information and are fully responsible for all activities that occur under your
              Profile. You agree to immediately notify the Company of any unauthorized use, or
              suspected unauthorized use of your Profile or any other breach of security. The
              Company cannot and will not be liable for any loss or damage arising from your failure
              to comply with the above requirements.
            </p>

            <p />

            <p>
              3.6. By using the Service, you agree to receive certain communications, such as
              updates on the App or a periodic e-mail newsletter from the Company. You can opt-out
              of non-essential communications by unsubscribing from the email notification.
            </p>

            <p />

            <h4>4. SERVICE</h4>

            <p />

            <p>
              4.1. You acknowledge that all the text, images, marks, logos, compilations (meaning
              the collection, arrangement and assembly of information), data, other content,
              software and materials displayed on the Service or used by the Company to operate the
              Service (including the App and the Content and excluding any User Content (as defined
              below)) is proprietary to us or to third parties.
            </p>

            <p />

            <p>
              4.2. The Company expressly reserves all rights, including all intellectual property
              rights, in all of the foregoing, and except as expressly permitted by these Terms, any
              use, redistribution, sale, decompilation, reverse engineering, disassembly,
              translation or other exploitation of them is strictly prohibited. The provision of the
              Service does not transfer to you or any third party any rights, title or interest in
              or to such intellectual property rights.
            </p>

            <p />

            <p>
              4.3. The information you submit to us as part of your registration, and any data, text
              and other material that you may provide or post to the App (&quot;User Content&quot;)
              remain your intellectual property, and the Company does not claim any ownership of the
              copyright or other proprietary rights in such registration information and the User
              Content. Notwithstanding the foregoing, you agree that the Company may retain copies
              of all registration information and the User Content and use such information and the
              User Content as reasonably necessary for or incidental to its operation of the Service
              and as described in these Terms and the Privacy Policy.
            </p>

            <p />

            <p>
              4.4. You grant the Company the non-exclusive, worldwide, transferable, perpetual,
              irrevocable right to publish, distribute, publicly display and perform the User
              Content in connection with the Service.
            </p>

            <p />

            <p>
              4.5. Subject to these Terms, the Company grants you a non-transferable, non-exclusive,
              license (without the right to sublicense) to (i) use the Service solely for your
              personal, non-commercial purposes, and (b) install and use the App, solely on your own
              handheld mobile device (e.g., iPhone, Android, etc. as applicable) and solely for your
              personal, non-commercial purposes.
            </p>

            <p />

            <p>
              4.6. You agree, and represent and warrant that your use of the Service, or any portion
              thereof will be consistent with the foregoing license, covenants, and restrictions and
              will neither infringe nor violate the rights of any other party or breach any contract
              or legal duty to any other parties. Also, you agree that you will comply with all
              applicable laws, regulations, and ordinances relating to the Service or your use of
              it, and you will be solely responsible for your own individual violations of any such
              laws.
            </p>

            <p />

            <p>
              4.7. You are solely responsible for obtaining the equipment and telecommunication
              services necessary to access the Service, and all fees associated therewith (such as
              computing devices and Internet service provider and airtime charges).
            </p>

            <p />

            <p>
              4.8. We retain the right to implement any changes to the Service (whether to free or
              paid features) at any time, with or without notice. You acknowledge that a variety of
              Company's actions may impair or prevent you from accessing the Service at certain
              times and/or in the same way, for limited periods or permanently, and agree that the
              Company has no responsibility or liability as a result of any such actions or results,
              including, without limitation, for the deletion of, or failure to make available to
              you, any content or services.
            </p>

            <p />

            <p>
              4.9. Your access to and use of the Service is at your own risk. The Company will have
              no responsibility for any harm to your computing system, loss of data, or other damage
              to you or any third party, including, without limitation, any bodily harm, that
              results from your access to or use of the Service, or reliance on any information or
              advice.
            </p>

            <p />

            <p>
              4.10. The Company has no obligation to provide you with customer support of any kind.
              However, the Company may provide you with customer support from time to time, at the
              Company's sole discretion.
            </p>

            <p />

            <p />

            <h4>5. APP STORES, THIRD PARTY ADS, OTHER USERS</h4>

            <p />

            <p>
              5.1. You acknowledge and agree that the availability of the App is dependent on the
              third party from which you received the App, e.g., the Apple iTunes App Store, and/or
              other app stores (collectively, &quot;App Stores&quot; and each, an &quot;App
              Store&quot;).
            </p>

            <p />

            <p>
              5.2. You agree to pay all fees charged by the App Stores in connection with the App.
              You agree to comply with, and your license to use the App is conditioned upon Your
              compliance with all applicable agreements, terms of use/service, and other policies of
              the App Stores. You acknowledge that the App Stores (and their subsidiaries) are a
              third party beneficiary of these Terms and will have the right to enforce these Terms.
            </p>

            <p />

            <p>
              5.3. The Service may contain links to third-party websites or resources and
              advertisements for third parties (collectively, &quot;Third Party Ads&quot;). Such
              Third-Party Ads are not under the control of the Company, and the Company is not
              responsible for any Third-Party Ads. The Company provides these Third-Party Ads only
              as a convenience and does not review, approve, monitor, endorse, warrant, or make any
              representations with respect to Third-Party Ads. Advertisements and other information
              provided by Third Party Sites Ads may not be wholly accurate. You acknowledge sole
              responsibility for and assume all risk arising from your use of any such websites or
              resources. When you link to a third-party site, the applicable service provider's
              terms and policies, including privacy and data gathering practices, govern. You should
              make whatever investigation you feel necessary or appropriate before proceeding with
              any transaction with any third party. Your transactions and other dealings with
              Third-Party Ads that are found on or through the App, including payment and delivery
              of related goods or services, are solely between you and such merchant or advertiser.
            </p>

            <p />

            <p>
              5.4. Each user of the Service is solely responsible for any and all his or her User
              Content. Because we do not control the User Content, you acknowledge and agree that we
              are not responsible for any User Content and we make no guarantees regarding the
              accuracy, currency, suitability, or quality of any User Content. We assume no
              responsibility for any User Content. Your interactions with other Service users are
              solely between you and such user. You agree that the Company will not be responsible
              for any loss or damage incurred as the result of any such interactions. If there is a
              dispute between you and any Service user, we are under no obligation to become
              involved.
            </p>

            <p />

            <p>
              5.5. You hereby release us, our officers, employees, agents, and successors from
              claims, demands any and all losses, damages, rights, claims, and actions of any kind
              including personal injuries, death, and property damage, that is either directly or
              indirectly related to or arises from any interactions with or conduct of any App
              Store, any other Service users, or any Third Party Ads.
            </p>

            <p />

            <h4>6. SUBSCRIPTION FEES AND PAYMENT</h4>

            <p />

            <p>
              6.1. The App is free to download. However, certain features of the Service are offered
              on a subscription basis for a fee. You will pay an App Store the applicable fees (and
              any related taxes) as they become due.
            </p>

            <p />

            <p>
              6.2. To the maximum extent permitted by applicable laws, we may change subscription
              fees at any time. We will give you reasonable notice of any such pricing changes by
              posting the new prices on or through the App and/or by sending you an email
              notification. If you do not wish to pay the new fees, you can cancel the applicable
              subscription before the change going into effect.
            </p>

            <p />

            <p>
              6.3. You authorize the App Stores to charge the applicable fees to the payment card
              that you submit.
            </p>

            <p />

            <p>
              6.4. By signing up for individual subscriptions, you agree that your subscription may
              be automatically renewed. Unless you cancel your subscription, you authorize the App
              Stores to charge you for the renewal term. The period of auto-renewal will be the same
              as your initial subscription period unless otherwise disclosed to you on the Service.
              The renewal rate will be no more than the rate for the immediately prior subscription
              period, excluding any promotional and discount pricing, unless we notify you of a rate
              change prior to your auto-renewal. You must cancel your subscription following the
              cancellation procedures disclosed to you for the particular subscription. We will not
              refund fees that may have accrued to your account and will not prorate fees for a
              canceled subscription.
            </p>

            <p />

            <p>
              6.5. We may offer a free trial subscription for the Service. The free trial provides
              you access to the Service for some time, with details specified when you sign up for
              the offer. Unless you cancel before the end of the free trial, or unless otherwise
              stated, your access to the Service will automatically continue, and you will be billed
              the applicable fees for the Service. We may send you a reminder when your free trial
              is about to end, but we do not guarantee any such notifications. It is ultimately your
              responsibility to know when the free trial will end. We reserve the right, in our
              absolute discretion, to modify or terminate any free trial offer, your access to the
              Service during the free trial, or any of these terms without notice and with no
              liability. We reserve the right to limit your ability to take advantage of multiple
              free trials.
            </p>

            <p />

            <p>
              6.6. The Service and your rights to use it expire at the end of the paid period of
              your subscription. If you do not pay the fees or charges due, we may make reasonable
              efforts to notify you and resolve the issue; however, we reserve the right to disable
              or terminate your access to the Service (and may do so without notice).
            </p>

            <p />

            <p>
              6.7. Subscriptions purchased via an App Store are subject to such App Store's refund
              policies. This means we cannot grant refunds. You will have to contact the App Store
              support.
            </p>

            <p />

            <h4>7. USER REPRESENTATIONS AND RESTRICTIONS</h4>

            <p />

            <p>7.1. By using the Service, you represent and warrant that:</p>

            <p />

            <p>7.1.1. you have the legal capacity and you agree to comply with these Terms;</p>

            <p />

            <p>7.1.2. you are not under the age of 16;</p>

            <p />

            <p>
              7.1.3. you will not access the Service through automated or non-human means, whether
              through a bot, script or otherwise;
            </p>

            <p />

            <p>7.1.4. you will not use the Service for any illegal or unauthorized purpose;</p>

            <p />

            <p>
              7.1.5. you are not located in a country that is subject to a U.S. government embargo,
              or that has been designated by the U.S. government as a &quot;terrorist
              supporting&quot; country;
            </p>

            <p />

            <p>
              7.1.6. you are not listed on any U.S. government list of prohibited or restricted
              parties; and
            </p>

            <p />

            <p>7.1.7. your use of the Service will not violate any applicable law or regulation.</p>

            <p />

            <p>
              7.2. If you provide any information that is untrue, inaccurate, not current, or
              incomplete, we have the right to refuse any and all current or future use of the
              Service (or any portion thereof).
            </p>

            <p />

            <p>
              7.3. You may not access or use the Service for any purpose other than that for which
              we make the Service available. The Service may not be used in connection with any
              commercial endeavors except those that are specifically endorsed or approved by us.
            </p>

            <p />

            <p>7.4. As a user of the Service, you agree not to:</p>

            <p />

            <p>
              7.4.1. systematically retrieve data or other content from the Service to create or
              compile, directly or indirectly, a collection, compilation, database, or directory
              without written permission from us;
            </p>

            <p />

            <p>7.4.2. make any unauthorized use of the Service;</p>

            <p />

            <p>
              7.4.3. make any modification, adaptation, improvement, enhancement, translation, or
              derivative work from the Service;
            </p>

            <p />

            <p>
              7.4.4. use the Service for any revenue-generating endeavor, commercial enterprise, or
              another purpose for which it is not designed or intended;
            </p>

            <p />

            <p>
              7.4.5. make the Service available over a network or other environmental permitting
              access or use by multiple devices or users at the same time;
            </p>

            <p />

            <p>
              7.4.6. use the Service for creating a product, service, or software that is, directly
              or indirectly, competitive with or in any way a substitute for the Service;
            </p>

            <p />

            <p />

            <p>
              7.4.7. use any proprietary information or any of our interfaces or our other
              intellectual property in the design, development, manufacture, licensing, or
              distribution of any applications, accessories, or devices for use with the Service;
            </p>

            <p />

            <p>
              7.4.8. circumvent, disable, or otherwise interfere with security-related features of
              the Service;
            </p>

            <p />

            <p>7.4.9. engage in unauthorized framing of or linking to the Service;</p>

            <p />

            <p>
              7.4.10. interfere with, disrupt, or create an undue burden on the Service or the
              networks or services connected to the Service;
            </p>

            <p />

            <p>
              7.4.11. decipher, decompile, disassemble, or reverse engineer any of the software
              comprising or in any way making up a part of the Service;
            </p>

            <p />

            <p>
              7.4.12. attempt to bypass any measures of the Service designed to prevent or restrict
              access to the Service, or any portion of the Service;
            </p>

            <p />

            <p>
              7.4.13. upload or distribute in any way files that contain viruses, worms, trojans,
              corrupted files, or any other similar software or programs that may damage the
              operation of another's computer;
            </p>

            <p />

            <p>
              7.4.14. use, launch, develop, or distribute any automated system, including without
              limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses
              the Service, or using or launching any unauthorized script or other software;
            </p>

            <p />

            <p>
              7.4.15. use the Service to send automated queries to any website or to send any
              unsolicited commercial e-mail;
            </p>

            <p />

            <p>
              7.4.16. disparage, tarnish, or otherwise harm, in our opinion, us and/or the Service;
            </p>

            <p />

            <p>
              7.4.17. use the Service in a manner inconsistent with any applicable laws or
              regulations; or
            </p>

            <p />

            <p>7.4.18. otherwise, infringe these Terms.</p>

            <p />

            <h4>8. DISCLAIMER OF ALL WARRANTIES</h4>

            <p />

            <p>
              THE APP, CONTENT, AND OTHER ASPECTS OF THE SERVICE ARE PROVIDED &quot;AS IS&quot; AND
              &quot;AS AVAILABLE.&quot; THE APP, CONTENT AND OTHER ASPECTS OF THE SERVICE ARE
              PROVIDED WITHOUT REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
              INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT,
              INTEGRATION, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES
              IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY
              DISCLAIMED. THE COMPANY AND ITS AFFILIATES, LICENSORS AND SUPPLIERS DO NOT WARRANT
              THAT: (I) THE SERVICE, CONTENT OR OTHER INFORMATION WILL BE TIMELY, ACCURATE, RELIABLE
              OR CORRECT; (II) THE SERVICE WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR
              PLACE; (III) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (IV) THE SERVICE WILL BE FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS; OR (IV) ANY RESULT OR OUTCOME CAN BE ACHIEVED.
            </p>

            <p />

            <h4>9. LIMITATION ON LIABILITY</h4>

            <p />

            <p>
              9.1. IN NO EVENT SHALL WE (AND OUR AFFILIATES) BE LIABLE TO YOU OR ANY THIRD PARTY FOR
              ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
              PUNITIVE DAMAGES ARISING FROM THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE
              SERVICE (INCLUDING THE APP OR CONTENT), OR THIRD-PARTY ADS, EVEN IF WE HAVE BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICE
              (INCLUDING THE APP, CONTENT AND USER CONTENT), AND THIRD-PARTY ADS ARE AT YOUR OWN
              DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
              COMPUTING SYSTEM OR LOSS OF DATA RESULTING THEREFROM.
            </p>

            <p />

            <p>
              9.2. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, YOU AGREE THAT THE
              AGGREGATE LIABILITY OF THE COMPANY TO YOU FOR ANY AND ALL CLAIMS ARISING FROM THE USE
              OF THE APP, CONTENT OR SERVICE IS LIMITED TO THE AMOUNTS YOU HAVE PAID TO THE COMPANY
              FOR ACCESS TO AND USE OF THE SERVICE. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
              FUNDAMENTAL ELEMENTS OF THE BASIS OF THE TERMS BETWEEN THE COMPANY AND YOU.
            </p>

            <p />

            <p>
              9.3. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR
              INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT
              APPLY TO YOU AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM JURISDICTION TO
              JURISDICTION.
            </p>

            <p />

            <h4>10. INDEMNITY</h4>

            <p />

            <p>
              You agree to indemnify and hold the Company, its successors, subsidiaries, affiliates,
              any related companies, its suppliers, licensors and partners, and the officers,
              directors, employees, agents and representatives of each of them harmless, including
              costs and attorneys' fees, from any claim or demand made by any third party due to or
              arising out of (i) your use of the Service, (ii) your User Content, or (ii) your
              violation of these Terms. The Company reserves the right, at your expense, to assume
              the exclusive defense and control of any matter for which you are required to
              indemnify us and you agree to cooperate with our defense of these claims. You agree
              not to settle any case without the prior written consent of the Company. The Company
              will use reasonable efforts to notify you of any such claim, action or proceeding upon
              becoming aware of it.
            </p>

            <p />

            <h4>11. INTERNATIONAL USE</h4>

            <p />

            <p>
              The Company makes no representation that the Service is accessible, appropriate or
              legally available for use in your jurisdiction, and accessing and using the Service is
              prohibited from territories where doing so would be illegal. You access the Service at
              your initiative and are responsible for compliance with local laws.
            </p>

            <p />

            <h4>12. MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER</h4>

            <p />

            <p>
              12.1. PLEASE READ THIS ARBITRATION PROVISION CAREFULLY TO UNDERSTAND YOUR RIGHTS.
              EXCEPT WHERE PROHIBITED BY LAW, YOU AGREE THAT ANY CLAIM THAT YOU MAY HAVE IN THE
              FUTURE MUST BE RESOLVED THROUGH FINAL AND BINDING CONFIDENTIAL ARBITRATION. YOU
              ACKNOWLEDGE AND AGREE THAT YOU ARE WAIVING THE RIGHT TO A TRIAL BY JURY. THE RIGHTS
              THAT YOU WOULD HAVE IF YOU WENT TO COURT, SUCH AS DISCOVERY OR THE RIGHT TO APPEAL,
              MAY BE MORE LIMITED OR MAY NOT EXIST.
            </p>

            <p />

            <p>
              12.2. YOU AGREE THAT YOU MAY ONLY BRING A CLAIM IN YOUR INDIVIDUAL CAPACITY AND NOT AS
              A PLAINTIFF (LEAD OR OTHERWISE) OR CLASS MEMBER IN ANY PURPORTED CLASS OR
              REPRESENTATIVE PROCEEDING. YOU FURTHER AGREE THAT THE ARBITRATOR MAY NOT CONSOLIDATE
              PROCEEDINGS OR CLAIMS OR OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS
              PROCEEDING.
            </p>

            <p />

            <p>
              12.3. YOU AND THE COMPANY, AND EACH OF ITS RESPECTIVE AGENTS, CORPORATE PARENTS,
              SUBSIDIARIES, AFFILIATES, PREDECESSORS IN INTEREST, SUCCESSORS, AND ASSIGNS, AGREE TO
              ARBITRATION (EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT), AS THE
              EXCLUSIVE FORM OF DISPUTE RESOLUTION EXCEPT AS PROVIDED FOR BELOW, FOR ALL DISPUTES
              AND CLAIMS ARISING OUT OF OR RELATING TO THIS AGREEMENT, THE SERVICE, OR THE PRIVACY
              POLICY, UNLESS YOU ARE LOCATED IN A JURISDICTION THAT PROHIBITS THE EXCLUSIVE USE OF
              ARBITRATION FOR DISPUTE RESOLUTION.
            </p>

            <p />

            <p>
              12.4. Arbitration is a more informal way to settle disputes than a lawsuit in court. A
              neutral arbitrator instead of a judge or jury is used in arbitration, which allows for
              more limited discovery than in court, and is subject to very limited review by courts.
              The same damages and relief that a court can award can be awarded by arbitrators.
              Please see more information about arbitration at http://www.adr.org.
            </p>

            <p />

            <p>
              12.5. A party which intends to seek arbitration must first send to the other a written
              notice of intent to arbitrate (a &quot;Notice&quot;) by an international courier with
              a tracking mechanism, or, in the absence of a mailing address provided by you to us,
              via any other method available to us, including via e-mail. The Notice to the Company
              must be addressed to: Florinis 7, Greg Tower, 2nd floor, 1065, Nicosia, Cyprus (as
              applicable, the &quot;Arbitration Notice Address&quot;). The Notice shall (i) describe
              the basis and nature of the claim or dispute; and (ii) set the specific relief sought
              (the &quot;Demand&quot;). If you and the Company do not reach an agreement to resolve
              the claim within 30 days after the Notice is received, then you or we may commence an
              arbitration proceeding as set forth below or file an individual claim in small claims
              court.
            </p>

            <p />

            <p>
              12.6. THE AMERICAN ARBITRATION ASSOCIATION (&quot;AAA&quot;) WILL EXCLUSIVELY
              ADMINISTER THE ARBITRATION IN ACCORDANCE WITH ITS COMMERCIAL ARBITRATION RULES AND THE
              SUPPLEMENTARY PROCEDURES FOR CONSUMER RELATED DISPUTES (THE &quot;Rules&quot;), AS
              MODIFIED BY THESE TERMS.
            </p>

            <p />

            <p>
              12.7. If you commence an arbitration against us, you are required to provide a second
              Notice to the Company at the Arbitration Notice Address within seven (7) days of
              arbitration commencement. The Rules and AAA forms are available online at
              http://www.adr.org. Unless your Demand is equal to or greater than $1,000 or was filed
              in bad faith, in which case you are solely responsible for the payment of the filing
              fee, if you are required to pay a filing fee to commence an arbitration against us,
              then we will promptly reimburse you for your confirmed payment of the filing fee upon
              our receipt of the second Notice at the Arbitration Notice Address that you have
              commenced arbitration along with a receipt evidencing payment of the filing fee.
            </p>

            <p />

            <p>
              12.8. The arbitration shall be conducted exclusively in English. A single, independent
              and impartial arbitrator with his or her primary place of business in Alexandria,
              Virginia (if you are from the United States) or in Limassol, Republic of Cyprus (if
              you are not from the United States) will be appointed pursuant to the Rules, as
              modified herein. You and the Company agree to comply with the following rules, which
              are intended to streamline the arbitration process and reduce the costs and burdens on
              the parties: (i) the arbitration will be conducted online and/or be solely based on
              written submissions, the specific manner to be chosen by the party initiating the
              arbitration; (ii) the arbitration will not require any personal appearance by the
              parties or witnesses unless otherwise mutually agreed in writing by the parties; and
              (iii) any judgment on the award the arbitrator renders may be entered in any court of
              competent jurisdiction.
            </p>

            <p />

            <p>
              12.9. TO THE FULLEST EXTENT PERMITTED UNDER LAW, YOU AND THE COMPANY AGREE THAT YOU
              AND THE COMPANY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
              CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS,
              REPRESENTATIVE, OR CONSOLIDATED PROCEEDING. FURTHER, YOU AGREE THAT THE ARBITRATOR MAY
              NOT CONSOLIDATE PROCEEDINGS OF MORE THAN ONE PERSON'S CLAIMS, AND MAY NOT OTHERWISE
              PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND THAT IF THIS
              SPECIFIC PROVISION IS FOUND TO BE UNENFORCEABLE, THEN THE ENTIRETY OF THIS MANDATORY
              ARBITRATION SECTION WILL BE NULL AND VOID.
            </p>

            <p />

            <p>
              12.10. The arbitrator shall have the exclusive and sole authority to resolve any
              dispute relating to the interpretation, construction, validity, applicability, or
              enforceability of these Terms, the Privacy Policy, and this arbitration provision. The
              arbitrator shall have the exclusive and sole authority to determine whether this
              arbitration clause can be enforced against a non-party to this agreement and whether a
              non-party to these Terms can enforce its provision against you or us.
            </p>

            <p />

            <p>
              12.11. Barring extraordinary circumstances, the arbitrator will issue his or her
              final, confidential decision within 120 days from the date the arbitrator is
              appointed. The arbitrator may extend this time limit for an additional 30 days upon a
              showing of good cause and in the interests of justice. All arbitration proceedings
              will be closed to the public and confidential, and all records relating thereto will
              be permanently sealed, except as necessary to obtain court confirmation of the
              arbitration award. The award of the arbitrator will be in writing and will include a
              statement setting forth the reasons for the disposition of any claim. The arbitrator
              shall apply the laws of the Commonwealth of Virginia without regard to its conflicts
              of laws principles in conducting the arbitration. You acknowledge that these terms and
              your use of the Service evidence a transaction involving interstate commerce. The
              United States Federal Arbitration Act (&quot;FAA&quot;) will govern the
              interpretation, enforcement, and proceedings pursuant to this Section 12. Any award
              rendered shall be final, subject to appeal under the FAA.
            </p>

            <p />

            <p>
              12.12. The abovestated provisions of this Section 12 shall not apply to any claim in
              which either party seeks equitable relief to protect such party's copyrights,
              trademarks, patents, or other intellectual property. For the avoidance of doubt, you
              agree that, in the event the Company or a third party breaches these Terms, the damage
              or harm, if any, caused to you will not entitle you to seek injunctive or other
              equitable relief against us, and your only remedy will be for monetary damages,
              subject to the limitations of liability set forth in these Terms.
            </p>

            <p />

            <p>
              12.13. You and we agree that, notwithstanding any other rights a party may have at law
              or in equity, any claim arising out of or related to these Terms (including the
              Privacy Policy) or the Service, excluding a claim for indemnification, must be
              initiated with the AAA or filed in small claims court in Alexandria, Virginia within
              one (1) year after the claim accrues. Otherwise, such a cause of action is permanently
              and forever barred. This one (1) year period includes the thirty (30) day pre-dispute
              procedure set forth in sub-clause 12.5 above.
            </p>

            <p />

            <p>
              12.14. All claims you bring against the Company must be resolved in accordance with
              this Section. All claims filed or brought contrary to this Section shall be considered
              improperly filed. Should you file a claim contrary to this Section, the Company may
              recover attorneys' fees, and reimbursement of its costs provided that the Company has
              notified you in writing of the improperly filed claim, and you fail to promptly
              withdraw such claim.
            </p>

            <p />

            <p>
              12.15. In the event that we make any material change to this arbitration provision
              (other than a change to our Arbitration Notice Address), you may reject any such
              change by sending us written notice to our Arbitration Notice Address within thirty
              (30) days of the change, in which case your Account and your license to use the
              Service will terminate immediately, and this Section, as in effect immediately prior
              to the amendments you reject, will survive the termination of these Terms.
            </p>

            <p />

            <p>
              12.16. If only clause 12.9 paragraph above or the entirety of this Section 12 is found
              to be unenforceable, then the whole of this Section 12 will be null and void and, in
              such case, the parties agree that the exclusive jurisdiction and venue described in
              Section 13 will govern any action arising out of or related to this Agreement.
            </p>

            <p />

            <p>
              12.17. YOU UNDERSTAND THAT YOU WOULD HAVE HAD A RIGHT TO LITIGATE THROUGH A COURT, TO
              HAVE A JUDGE OR JURY DECIDE YOUR CASE, AND TO BE PARTY TO A CLASS OR REPRESENTATIVE
              ACTION. HOWEVER, YOU UNDERSTAND AND AGREE TO HAVE ANY CLAIMS DECIDED INDIVIDUALLY AND
              ONLY THROUGH BINDING, FINAL, AND CONFIDENTIAL ARBITRATION.
            </p>

            <p />

            <p>
              12.18. YOU HAVE THE RIGHT TO OPT-OUT OF THIS ARBITRATION PROVISION WITHIN THIRTY (30)
              DAYS FROM THE DATE THAT YOU FIRST USE, OR ATTEMPT TO USE, THE SERVICE BY WRITING TO{' '}
              <a href="mailto:info@herosnatch.com">info@herosnatch.com</a> OR TO THE ARBITRATION
              NOTICE ADDRESS. FOR YOUR OPT-OUT TO BE EFFECTIVE, YOU MUST SUBMIT A SIGNED WRITTEN
              NOTICE OPTING OUT AND CONTAINING ENOUGH DETAILS ABOUT YOU FOR US TO BE ABLE TO
              IDENTIFY YOU WITHIN THIRTY (30) DAYS. IF MORE THAN THIRTY (30) DAYS HAVE PASSED, YOU
              ARE NOT ELIGIBLE TO OPT OUT OF THIS PROVISION AND YOU MUST PURSUE YOUR CLAIM THROUGH
              BINDING ARBITRATION AS SET FORTH IN THIS AGREEMENT.
            </p>

            <p />

            <h4>13. GOVERNING LAW</h4>

            <p />

            <p>
              13.1. The laws of Cyprus, excluding its conflicts of law principles, govern these
              Terms and your use of the Service.
            </p>

            <p />

            <p>
              13.2. To the extent that any action relating to any dispute hereunder is permitted to
              be brought in a court of law, such action will be subject to the exclusive
              jurisdiction of:
            </p>

            <p />

            <p>
              13.2.1. the state and federal courts in the City of Alexandria, Virginia - if you are
              a resident of the United States; or
            </p>

            <p />

            <p>
              13.2.2. the courts of the Republic of Cyprus - if you are not a resident of the United
              States;
            </p>

            <p />

            <p>
              and you hereby irrevocably submit to personal jurisdiction and venue in such courts,
              and waive any defense of improper venue or inconvenient forum.
            </p>

            <p />

            <h4>14. MISCELLANEOUS PROVISIONS</h4>

            <p />

            <p>
              14.1. No delay or omission by us in exercising any of our rights occurring upon any
              noncompliance or default by you with respect to these Terms will impair any such right
              or be construed to be a waiver thereof, and a waiver by the Company of any of the
              covenants, conditions or agreements to be performed by you will not be construed to be
              a waiver of any succeeding breach thereof or of any other covenant, condition or
              agreement hereof contained.
            </p>

            <p />

            <p>
              14.2. Subject to Section 12, if any provision of these Terms is found to be invalid or
              unenforceable, then these Terms will remain in full force and effect and will be
              reformed to be valid and enforceable while reflecting the intent of the parties to the
              greatest extent permitted by law.
            </p>

            <p />

            <p>
              14.3. Except as otherwise expressly provided herein, these Terms set forth the entire
              agreement between you and the Company regarding its subject matter, and supersede all
              prior promises, agreements or representations, whether written or oral, regarding such
              subject matter.
            </p>

            <p />

            <p>
              14.4. The Company may transfer or assign any and all of its rights and obligations
              under these Terms to any other person, in any way, including by novation, and by
              accepting these Terms you give the Company consent to any such assignment and
              transfer. You confirm that placing on the Service of a version of these Terms
              indicating another person as a party to the Terms shall constitute valid notice to you
              of the transfer of Company's rights and obligations under the Agreement (unless
              otherwise is expressly indicated).
            </p>

            <p />

            <p>
              14.5. All information communicated on the Service is considered an electronic
              communication. When you communicate with us through or on the Service or via other
              forms of electronic media, such as e-mail, you are communicating with us
              electronically. You agree that we may communicate electronically with you and that
              such communications, as well as notices, disclosures, agreements, and other
              communications that we provide to you electronically, are equivalent to communications
              in writing and shall have the same force and effect as if they were in writing and
              signed by the party sending the communication. You further acknowledge and agree that
              by clicking on a button labeled &quot;SUBMIT,&quot; &quot;CONTINUE,&quot;
              &quot;REGISTER,&quot; &quot;I AGREE&quot; or similar links or buttons, you are
              submitting a legally binding electronic signature and are entering into a legally
              binding contract. You acknowledge that your electronic submissions constitute your
              agreement and intent to be bound by these Terms. YOU HEREBY AGREE TO THE USE OF
              ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS AND TO ELECTRONIC DELIVERY
              OF NOTICES, POLICIES AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED THROUGH THE
              SERVICE.
            </p>

            <p />

            <p>
              14.6. In no event shall the Company be liable for any failure to comply with these
              Terms to the extent that such failure arises from factors outside the Company's
              reasonable control.
            </p>

            <p />

            <h4>15. CONTACT</h4>

            <p />

            <p>
              If you want to send any notice under these Terms or have any questions regarding the
              Service, you may contact us at:{' '}
              <a href="mailto:info@herosnatch.com">info@herosnatch.com</a>.
            </p>

            <p />

            <p>I HAVE READ THESE TERMS AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE.</p>

            <p />

            <p>Last Updated: December 8, 2019</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Terms.propTypes = {};

// export const pageQuery = graphql`
//     query($path: String) {
//         markdownRemark(frontmatter: { path: { eq: $path } }) {
//             html
//             frontmatter {
//                 date(formatString: "MMMM DD, YYYY")
//                 path
//                 title
//             }
//         }
//     }
// `;

// export default (props) => (
//   <PageQuery
//     query={graphql`
//       query($path: String) {
//         markdownRemark(frontmatter: { path: { eq: $path } }) {
//             html
//             frontmatter {
//                 date(formatString: "MMMM DD, YYYY")
//                 path
//                 title
//             }
//         }
//       }
//     `}
//     render={data => <Privacy data={data} {...props} />}
//   />
// );
